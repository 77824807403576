import React, { useState, useEffect } from 'react';

const Home = () => {
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        fetchPhotos();
        const loadMorePhotos = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 200
            ) {
                fetchPhotos();
            }
        };
        loadMorePhotos();
        window.addEventListener('scroll', loadMorePhotos);
        return () => {
            window.removeEventListener('scroll', loadMorePhotos);
        };
    }, []);

    const fetchPhotos = () => {
        fetch('https://source.unsplash.com/random')
            .then(response => {
                //console.log(response.url)
                setPhotos(prevPhotos => [...prevPhotos, response.url]);
            })
            .catch(error => {
                console.error('Error fetching photos:', error);
            });
    };

    return (
        <div>
            <h1>Homepage</h1>
            <div className="productBox">
                {photos.map((photo, index) => (
                    <a key={index} href="/product/143" className="productLink">
                        <img src={photo} className='productImage' style={{ height: '300px' }} alt="Random" />
                        <br /><a href='/product/143' className='productLink'>Picture</a>

                    </a>
                ))}
            </div>
        </div>
    );
};

export default Home;
