import { useParams } from "react-router-dom"
import { useContext } from 'react'
import Context from "../components/Context"

export default function ProductDetail() {
    const userData = useContext(Context)
    const params = useParams()
    let ProductData = null

    //database lookup using id
    if (params.id === '143') {
        ProductData = {
            name: 'Shirt',
            price: 50.00,
            image: require('../assets/images/Flower-Shirt.jpg')
        }
    }

    return (
        <>
            <h1>Product Details</h1>
            <p>The product ID is {params.id}</p>
            <p>Cart Items: {userData.cartItems}</p>
            {
                ProductData != null ?
                    <>
                        <img src={ProductData.image} alt={ProductData.name} />
                        <h2>{ProductData.name} - ${ProductData.price}</h2>
                    </>
                    : ''
            }
        </>
    )
}